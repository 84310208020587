<template>
  <div class="type_group" v-loading="loading" element-loading-background="rgba(24,34,45,0.9)">
    <vue-scroll>
      <div class="header">
        <section-title title="图表类型"></section-title>
      </div>
      <div class="group_list">
        <div
          v-for="item in groupList"
          :key="item.clueObjectTypeId"
          :class="['group_list_item']"
          @click="toChartList(item)">
          <div class="item_cover flex align_center justify_center">
            <p class="text_color_white">
              <span class="text_size_14">所属图表数：</span>
              <span class="text_size_24">{{ item.chartCount }}</span>
            </p>
          </div>
          <div class="item_footer">
            <p class="title text_size_14 text_color_white text_line_height_30">{{ item.title | formatType }}</p>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import * as api from '~/api'
import { chartTypes } from '../../../chart-detail/libs/chart-database'
export default {
  data () {
    return {
      loading: false,
      // 分组列表
      groupList: []
    }
  },
  computed: {
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    projectName () {
      return decodeURIComponent(this.$route.query.projectName) || ''
    }
  },
  filters: {
    formatType (value) {
      let result = ''
      const element = chartTypes.find(element => {
        return element.value === value
      })
      result = element ? element.label : ''
      return result
    }
  },
  created () {
    // 获取指定项目下的图表类型分组列表
    this.getTypeGroupList()
  },
  methods: {
    // 获取指定项目下的图表类型分组列表
    getTypeGroupList (result) {
      this.loading = true
      api.getTypeGroupList({
        spaceId: this.projectId
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('类型分组列表', res.data.data)
          this.groupList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 前往此分组的图表列表
    toChartList (item) {
      this.$router.push({
        path: '/project-detail/chart-list',
        query: {
          listSource: 'type-group',
          // 传入的是图表类型
          sourceTitle: encodeURIComponent(item.title),
          projectId: this.projectId,
          projectName: this.projectName,
          groupId: item.title,
          groupType: 'chart_type'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .type_group {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0px 30px 0 30px;
    .header {
      display: flex;
      flex-direction: column;
      padding: 30px 10px 0 10px;
      margin-bottom: 20px;
    }
    .group_list {
      display: flex;
      flex-wrap: wrap;
      .group_list_item {
        position: relative;
        display: flex;
        flex-direction: column;
        width:270px;
        height:170px;
        border:1px solid $border-color-base;
        border-radius:4px;
        margin: 0 10px 20px 10px;
        background-image: url('../../../../../assets/img/list_item_bg.png');
        background-repeat: no-repeat;
        cursor: pointer;
        .item_cover {
          flex: 1;
          border-radius: 4px 4px 0px 0px;
          transition: all 0.3s ease;
        }
        .item_footer {
          flex: none;
          width: 100%;
          height: 49px;
          padding: 10px 20px;
          background-color:rgba(3,13,23,0.5);
          border-radius: 0px 0px 4px 4px;
          .title {
            transition: all 0.3s ease;
          }
        }
        &:hover {
          .item_cover {
            background-color: rgba(66,76,87,0.8);
          }
          .item_footer {
            .title {
              color: #6AEBFF;
            }
          }
        }
      }
    }
  }
</style>
